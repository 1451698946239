<template>
  <div>
    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('BirthDay')" label-for="BirthDate">
            <b-form-datepicker
              id="BirthDate-datepicker"
              :placeholder="$t('BirthDay')"
              v-model="patientModel.BirthDate"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
            :label="$t('HijriBirthDate')"
            label-for="HijriBirthDate"
          >
            <b-form-datepicker
              id="BirthDate-datepicker"
              :placeholder="$t('BirthDay')"
              v-model="patientModel.BirthDate"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('HijriBirthDate')"
            label-for="HijriBirthDate"
          >
          <date-picker v-model="patientModel.HijriBirthDate" :locale="'ar-sa'" :calendar="'hijri'"/>
          <datepicker
            :placeholder="$t('BirthDay')"
            :locale="'ar-sa'"
            v-model="patientModel.HijriBirthDate"
            class="vx-col lg:w-3/12"
            :calendar="'hijri'"
          ></datepicker>
          </b-form-group>
        </b-col> -->
        <!-- Field: Gender -->

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Gender')" label-for="Gender">
            <v-select
              v-model="patientModel.GenderID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="genders"
              :reduce="(val) => val.ID"
              :clearable="false"
              label="Name"
              input-id="Gender"
            />
          </b-form-group>
        </b-col>
        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Areyoudiagnosedwithloworhighpressure')">
            <b-row>
              <b-form-radio-group
                @change="
                  patientModel.PatientMedicalHistory.IsLowPressure = null
                "
                id="highpressure-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsHavePresssure"
                name="highpressure"
                :options="[{ text: $t('Highpressure'), value: true }]"
              />
              <b-form-radio-group
                @change="
                  patientModel.PatientMedicalHistory.IsHavePresssure = null
                "
                id="Lowpressure-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsLowPressure"
                name="Lowpressure"
                :options="[{ text: $t('Lowpressure'), value: true }]"
              />
              <b-form-radio-group
                @change="
                  patientModel.PatientMedicalHistory.IsLowPressure = null
                "
                id="IsHavePresssure-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsHavePresssure"
                name="IsHavePresssure"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>
        <!-- Field: MaritalStatus -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('MaritalStatus')">
            <b-form-radio-group
              id="MaritalStatus-radio-group"
              v-model="patientModel.MaritalStatusID"
              name="MaritalStatus"
              :options="maritalStatuses"
            />
          </b-form-group>
        </b-col>
        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />
        <!--Areyouasmoker  -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Areyouasmoker')">
            <b-form-checkbox
              v-model="patientModel.PatientMedicalHistory.IsSmoker"
              switch
              inline
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.IsSmoker"
            :label="$t('NumberOfCigarettesPerDay')"
            label-for="NumberOfCigarettesPerDay"
          >
            <b-form-input
              id="NumberOfCigarettesPerDay"
              type="number"
              v-model="
                patientModel.PatientMedicalHistory.NumberOfCigarettesPerDay
              "
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveBloodTransfusion')">
            <b-form-checkbox
              v-model="
                patientModel.PatientMedicalHistory.ISHaveBloodTransfusion
              "
              switch
              inline
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveBloodTransfusion"
            :label="$t('NumberOfBloodTransfusion')"
            label-for="NumberOfBloodTransfusion"
          >
            <b-form-input
              id="NumberOfBloodTransfusion"
              type="number"
              v-model="
                patientModel.PatientMedicalHistory.NumberOfBloodTransfusion
              "
            />
          </b-form-group>
        </b-col>
        <!-- new  -->
        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />
        <b-col cols="12" md="6">
          <b-form-group :label="$t('IsHaveChronicDiseases')">
            <b-row class="m-1">
              <b-form-radio-group
                id="IsHaveChronicDiseases-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.IsHaveChronicDiseases
                "
                name="IsHaveChronicDiseases"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                @change="
                  patientModel.PatientMedicalHistory.ChronicDiseases = null
                "
                id="noIsHaveChronicDiseases-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.IsHaveChronicDiseases
                "
                name="noIsHaveChronicDiseases"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.IsHaveChronicDiseases"
            :label="$t('MentionIt')"
            label-for="ChronicDiseases"
          >
            <b-form-input
              id="ChronicDiseases"
              v-model="patientModel.PatientMedicalHistory.ChronicDiseases"
            />
          </b-form-group>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 1 -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('IsHaveMedications')">
            <b-row class="m-1">
              <b-form-radio-group
                id="IsHaveMedications-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsHaveMedications"
                name="IsHaveMedications"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noIsHaveMedications-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsHaveMedications"
                name="noIsHaveMedications"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.IsHaveMedications"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForGeneralMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >
            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForGeneralMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 2 -->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('IsHaveNutritionalSupplements')">
            <b-row class="m-1">
              <b-form-radio-group
                id="IsHaveNutritionalSupplements-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .IsHaveNutritionalSupplements
                "
                name="IsHaveNutritionalSupplements"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noIsHaveNutritionalSupplements-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .IsHaveNutritionalSupplements
                "
                name="noIsHaveNutritionalSupplements"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.IsHaveNutritionalSupplements"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForNutritionalSupplementsMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForNutritionalSupplementsMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 3 -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('HaveHeartProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveHeartProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
                name="ISHaveHeartProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveHeartProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
                name="noISHaveHeartProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
            :label="$t('MentionIt')"
            label-for="HeartDiseases"
          >
            <b-form-input
              id="HeartDiseases"
              v-model="patientModel.PatientMedicalHistory.HeartDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveHeartProblem"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForHeartMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForHeartMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 4 -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveChestProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveChestProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveChestProblem"
                name="ISHaveChestProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveChestProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveChestProblem"
                name="noISHaveChestProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveChestProblem"
            :label="$t('MentionIt')"
            label-for="HeartDiseases"
          >
            <b-form-input
              id="ChestDiseases"
              v-model="patientModel.PatientMedicalHistory.ChestDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveChestProblem"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForChestProblemMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForChestProblemMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 5 -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveNeurologicalProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveNeurologicalProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveNeurologicalProblem
                "
                name="ISHaveNeurologicalProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveNeurologicalProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveNeurologicalProblem
                "
                name="noISHaveNeurologicalProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveNeurologicalProblem"
            :label="$t('MentionIt')"
            label-for="HeartDiseases"
          >
            <b-form-input
              id="NeurologicalDiseases"
              v-model="patientModel.PatientMedicalHistory.NeurologicalDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveNeurologicalProblem"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForNeurologicalProblemMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForNeurologicalProblemMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 6 -->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveInfectiousDiseaseBefore')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveInfectiousDiseaseBefore-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveInfectiousDiseaseBefore
                "
                name="ISHaveInfectiousDiseaseBefore"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveInfectiousDiseaseBefore-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveInfectiousDiseaseBefore
                "
                name="noISHaveInfectiousDiseaseBefore"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveInfectiousDiseaseBefore
            "
            :label="$t('MentionIt')"
            label-for="InfectiousDiseaseDiseases"
          >
            <b-form-input
              id="InfectiousDiseaseDiseases"
              v-model="
                patientModel.PatientMedicalHistory.InfectiousDiseaseDiseases
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="
            patientModel.PatientMedicalHistory.ISHaveInfectiousDiseaseBefore
          "
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForInfectiousDiseaseBeforeMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForInfectiousDiseaseBeforeMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 7-->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHavePsychiatricDisturbances')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHavePsychiatricDisturbances-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHavePsychiatricDisturbances
                "
                name="ISHavePsychiatricDisturbances"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHavePsychiatricDisturbances-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHavePsychiatricDisturbances
                "
                name="noISHavePsychiatricDisturbances"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHavePsychiatricDisturbances
            "
            :label="$t('MentionIt')"
            label-for="PsychiatricDisturbancesDiseases"
          >
            <b-form-input
              id="PsychiatricDisturbancesDiseases"
              v-model="
                patientModel.PatientMedicalHistory
                  .PsychiatricDisturbancesDiseases
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="
            patientModel.PatientMedicalHistory.ISHavePsychiatricDisturbances
          "
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForPsychiatricDisturbancesMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForPsychiatricDisturbancesMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 8-->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveDigestiveProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveDigestiveProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveDigestiveProblem
                "
                name="ISHaveDigestiveProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveDigestiveProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveDigestiveProblem
                "
                name="noISHaveDigestiveProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveDigestiveProblem"
            :label="$t('MentionIt')"
            label-for="DigestiveDiseases"
          >
            <b-form-input
              id="DigestiveDiseases"
              v-model="patientModel.PatientMedicalHistory.DigestiveDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveDigestiveProblem"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForDigestiveProblemMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForDigestiveProblemMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 9-->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveReproductiveSystemProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveReproductiveSystemProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveReproductiveSystemProblem
                "
                name="ISHaveReproductiveSystemProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveReproductiveSystemProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveReproductiveSystemProblem
                "
                name="noISHaveReproductiveSystemProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveReproductiveSystemProblem
            "
            :label="$t('MentionIt')"
            label-for="ReproductiveSystemDiseases"
          >
            <b-form-input
              id="ReproductiveSystemDiseases"
              v-model="
                patientModel.PatientMedicalHistory.ReproductiveSystemDiseases
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="
            patientModel.PatientMedicalHistory.ISHaveReproductiveSystemProblem
          "
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForReproductiveSystemMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForReproductiveSystemMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 10-->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveBlodProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveBlodProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveBlodProblem"
                name="ISHaveBlodProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveBlodProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveBlodProblem"
                name="noISHaveBlodProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveBlodProblem"
            :label="$t('MentionIt')"
            label-for="BlodDiseases"
          >
            <b-form-input
              id="BlodDiseases"
              v-model="patientModel.PatientMedicalHistory.BlodDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveBlodProblem"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForBlodProblemMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForBlodProblemMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 11-->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveRheumaticProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveRheumaticProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveRheumaticProblem
                "
                name="ISHaveRheumaticProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveRheumaticProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveRheumaticProblem
                "
                name="noISHaveRheumaticProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveRheumaticProblem"
            :label="$t('MentionIt')"
            label-for="RheumaticDiseases"
          >
            <b-form-input
              id="RheumaticDiseases"
              v-model="patientModel.PatientMedicalHistory.RheumaticDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveRheumaticProblem"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForRheumaticProblemMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForRheumaticProblemMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 12-->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveVisionProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveVisionProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveVisionProblem"
                name="ISHaveVisionProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveVisionProblem-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveVisionProblem"
                name="noISHaveVisionProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveVisionProblem"
            :label="$t('MentionIt')"
            label-for="VisionDiseases"
          >
            <b-form-input
              id="VisionDiseases"
              v-model="patientModel.PatientMedicalHistory.VisionDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveVisionProblem"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForVisionProblemMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForVisionProblemMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- medication 13-->
        <b-col cols="12" md="3">
          <b-form-group :label="$t('ISHaveAnyCancerType')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveAnyCancerType-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveAnyCancerType"
                name="ISHaveAnyCancerType"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveAnyCancerType-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHaveAnyCancerType"
                name="noISHaveAnyCancerType"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveAnyCancerType"
            :label="$t('MentionIt')"
            label-for="CancerDiseases"
          >
            <b-form-input
              id="CancerDiseases"
              v-model="patientModel.PatientMedicalHistory.CancerDiseases"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="patientModel.PatientMedicalHistory.ISHaveAnyCancerType"
        >
          <div id="data-list-list-view" class="data-list-container">
            <b-button
              variant="primary"
              @click="
                MedicineModel.ForCancerMedication = true;
                showMedicationPopup = true;
              "
              >{{ $t("CanAddAttMedicine") }}</b-button
            >

            <vs-table
              :data="
                patientModel.PatientMedicalHistory
                  .PatientMedicalHistoryMedications
              "
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>{{ $t("MedicineName") }}</vs-th>
                <vs-th>{{ $t("Action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  v-if="tr.ForCancerMedication"
                >
                  <vs-td>
                    {{ tr.MedicineName }}
                  </vs-td>
                  <vs-td>
                    <b-row>
                      <a
                        v-if="tr.AttachmentURL"
                        :href="baseUrl + tr.AttachmentURL"
                        target="blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </a>
                      <p @click="deleteMedicine(indextr)">
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                        ></feather-icon>
                      </p>
                    </b-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- surgery-->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('IsSurgeryBefore')">
            <b-row class="m-1">
              <b-form-radio-group
                id="IsSurgeryBefore-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore"
                name="IsSurgeryBefore"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noIsSurgeryBefore-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsSurgeryBefore"
                name="noIsSurgeryBefore"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.IsSurgeryBefore"
            :label="$t('MentionIt')"
            label-for="SurgeryBeforeName"
          >
            <b-form-input
              id="SurgeryBeforeName"
              v-model="patientModel.PatientMedicalHistory.SurgeryBeforeName"
            />
          </b-form-group>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- ISHaveHistoryHospitalization-->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('ISHaveHistoryHospitalization')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveHistoryHospitalization-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveHistoryHospitalization
                "
                name="ISHaveHistoryHospitalization"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveHistoryHospitalization-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveHistoryHospitalization
                "
                name="noISHaveHistoryHospitalization"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveHistoryHospitalization
            "
            :label="$t('ReasonOfHistoryHospitalization')"
            label-for="ReasonOfHistoryHospitalization"
          >
            <b-form-input
              id="ReasonOfHistoryHospitalization"
              v-model="
                patientModel.PatientMedicalHistory
                  .ReasonOfHistoryHospitalization
              "
            />
          </b-form-group>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- ISHaveHistoryHospitalization-->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('ISHavePet')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHavePet-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHavePet"
                name="ISHavePet"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHavePet-radio-group"
                v-model="patientModel.PatientMedicalHistory.ISHavePet"
                name="noISHavePet"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHavePet"
            :label="$t('PetName')"
            label-for="PetName"
          >
            <b-form-input
              id="PetName"
              v-model="patientModel.PatientMedicalHistory.PetName"
            />
          </b-form-group>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- ISHaveSensitiveProblem-->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('ISHaveSensitiveProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveSensitiveProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveSensitiveProblem
                "
                name="ISHaveSensitiveProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveSensitiveProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveSensitiveProblem
                "
                name="noISHaveSensitiveProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            v-if="patientModel.PatientMedicalHistory.ISHaveSensitiveProblem"
            :label="$t('MentionIt')"
            label-for="SensitiveName"
          >
            <b-form-input
              id="SensitiveName"
              v-model="patientModel.PatientMedicalHistory.SensitiveName"
            />
          </b-form-group>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- ISHaveParentDiseasesProblem-->
        <b-col cols="12" md="6">
          <b-form-group :label="$t('ISHaveParentDiseasesProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveParentDiseasesProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveParentDiseasesProblem
                "
                name="ISHaveParentDiseasesProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveParentDiseasesProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveParentDiseasesProblem
                "
                name="noISHaveParentDiseasesProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveParentDiseasesProblem
            "
            :label="$t('ParentDiseasesName')"
            label-for="ParentDiseasesName"
          >
            <b-form-input
              id="ParentDiseasesName"
              v-model="patientModel.PatientMedicalHistory.ParentDiseasesName"
            />
          </b-form-group>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- ISHaveBrothorsDiseasesProblem-->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('ISHaveBrothorsDiseasesProblem')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveBrothorsDiseasesProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveBrothorsDiseasesProblem
                "
                name="ISHaveBrothorsDiseasesProblem"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveBrothorsDiseasesProblem-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory
                    .ISHaveBrothorsDiseasesProblem
                "
                name="noISHaveBrothorsDiseasesProblem"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveBrothorsDiseasesProblem
            "
            :label="$t('IsBrother')"
          >
            <b-row class="m-1">
              <b-form-radio-group
                id="IsBrother-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsBrother"
                name="IsBrother"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noIsBrother-radio-group"
                v-model="patientModel.PatientMedicalHistory.IsBrother"
                name="noIsBrother"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveBrothorsDiseasesProblem
            "
            :label="$t('BrothorsDiseasesName')"
            label-for="BrothorsDiseasesName"
          >
            <b-form-input
              id="BrothorsDiseasesName"
              v-model="patientModel.PatientMedicalHistory.BrothorsDiseasesName"
            />
          </b-form-group>
        </b-col>

        <hr
          style="
            display: block;
            height: 5px;
            border: 0;
            width: 100%;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
          "
        />

        <!-- ISHaveAnyDiseasesProblemNow-->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('ISHaveAnyDiseasesProblemNow')">
            <b-row class="m-1">
              <b-form-radio-group
                id="ISHaveAnyDiseasesProblemNow-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveAnyDiseasesProblemNow
                "
                name="ISHaveAnyDiseasesProblemNow"
                :options="[{ text: $t('yes'), value: true }]"
              />
              <b-form-radio-group
                id="noISHaveAnyDiseasesProblemNow-radio-group"
                v-model="
                  patientModel.PatientMedicalHistory.ISHaveAnyDiseasesProblemNow
                "
                name="noISHaveAnyDiseasesProblemNow"
                :options="[{ text: $t('no'), value: false }]"
              />
            </b-row>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveAnyDiseasesProblemNow
            "
            :label="$t('ReasoneDiseasesProblemNow')"
            label-for="ReasoneDiseasesProblemNow"
          >
            <b-form-input
              id="ReasoneDiseasesProblemNow"
              v-model="
                patientModel.PatientMedicalHistory.ReasoneDiseasesProblemNow
              "
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
            v-if="
              patientModel.PatientMedicalHistory.ISHaveAnyDiseasesProblemNow
            "
            :label="$t('PeriodDiseasesProblemNow')"
            label-for="PeriodDiseasesProblemNow"
          >
            <b-form-input
              id="PeriodDiseasesProblemNow"
              v-model="
                patientModel.PatientMedicalHistory.PeriodDiseasesProblemNow
              "
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <vs-popup
      title=""
      :active.sync="showMedicationPopup"
      v-if="showMedicationPopup"
    >
      <b-form-group :label="$t('MedicineName')" label-for="MedicineName">
        <b-form-input id="MedicineName" v-model="MedicineModel.MedicineName" />
      </b-form-group>
      <input
        type="file"
        class="mt-2"
        @change="AttachmentUploaded"
        @on-success="successUpload"
      />
      <b-button
        variant="primary"
        @click="AddMedicinToList"
        :disabled="!(MedicineModel.AttachmentURL || MedicineModel.MedicineName)"
        ><span style="font-size: large">+</span></b-button
      >
    </vs-popup>
  </div>
</template>

<script>
import moduleGender from "@/store/settings/gender/moduleGender.js";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BRow,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import { domain } from "@/gloabelConstant.js";
import axios from "@/axios.js";

export default {
  components: {
    Datepicker,
    BFormDatepicker,
    BRow,
    BCard,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      showMedicationPopup: false,
      MedicineModel: { AttachmentURL: "", MedicineName: "" },
      uploadUrl: domain + "api/Common/UploadFile",
      options: [],
      baseUrl: domain,
      radios1: "luis",
      date: null,
      surgeryModel: {},
      medicationModel: {},
      smokeLevels: [
        { text: this.$t("Heavy"), value: 1 },
        { text: this.$t("Moderate"), value: 2 },
        { text: this.$t("Occasionaly"), value: 3 },
      ],
      maritalStatuses: [
        { text: this.$t("Single"), value: 1 },
        { text: this.$t("Married"), value: 4 },
        { text: this.$t("Divorced"), value: 2 },
        { text: this.$t("Widowed"), value: 3 },
      ],
    };
  },
  props: {
    patientModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    genders() {
      return this.$store.state.GenderList.genders;
    },
    companies() {
      var temp = this.$store.state.InsuranceCompanyList.InsuranceCompanies;
      if (!temp.filter((e) => e.ID == null)[0])
        temp.push({ ID: null, Name: "Other" });
      return temp;
    },
  },
  methods: {
    AddMedicinToList() {
      // if(id==1){
      //   this.MedicineModel.ForGeneralMedication = true;
      // }
      // else if(id==2){
      //   this.MedicineModel.ForNutritionalSupplementsMedication = true;
      // }
      this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications.unshift(
        this.MedicineModel
      );
      this.MedicineModel = { AttachmentURL: "", MedicineName: "" };
      this.showMedicationPopup = false;
    },
    AddMedicine() {
      if (
        this.patientModel.PatientMedicalHistory
          .PatientMedicalHistoryMedications == undefined
      ) {
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications =
          [];
      }
      if (
        this.patientModel.PatientMedicalHistory
          .PatientMedicalHistoryMedications != undefined
      ) {
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications.push(
          this.medicationModel
        );
      } else {
        debugger;
        var randListMed = [];
        randListMed.push(this.medicationModel);
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications =
          randListMed;
      }

      this.medicationModel = {};
    },
    deleteMedicine(index) {
      this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications.splice(
        index,
        1
      );
    },
    AddSurgery() {
      if (
        this.patientModel.PatientMedicalHistory
          .PatientMedicalHistoryPastSurgeries == undefined
      ) {
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries =
          [];
      }

      if (
        this.patientModel.PatientMedicalHistory
          .PatientMedicalHistoryPastSurgeries != undefined
      ) {
        debugger;
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries.push(
          this.surgeryModel
        );
        debugger;
      } else {
        debugger;
        var randList = [];
        randList.push(this.surgeryModel);
        this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries =
          randList;
      }
      this.surgeryModel = {};
      debugger;
    },
    deleteSurgery(index) {
      debugger;
      this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries.splice(
        index,
        1
      );
    },
    successUpload(event) {
      debugger;

      window.showAddSuccess();
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        debugger;
        let data = new FormData();
        data.append("file", input.target.files[0]);
        debugger;
        axios
          .post(this.uploadUrl, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            debugger;
            this.MedicineModel.AttachmentURL = response.data;
          });
      }
    },
  },
  created() {
    if (!moduleGender.isRegistered) {
      this.$store.registerModule("GenderList", moduleGender);
      moduleGender.isRegistered = true;
    }
    this.$store.dispatch("GenderList/GetAllGenders");
    this.patientModel.PatientMedicalHistory.PatientMedicalHistoryMedications =
      [];
    this.patientModel.PatientMedicalHistory.PatientMedicalHistoryPastSurgeries =
      [];
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>