<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="baseURL+patientModel.ProfileImagePath"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ patientModel.displayName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.uploadImgInput.click()"
        >
          <input
          type="file"
          class="hidden"
          ref="uploadImgInput"
          @change="updateCurrImg"
          accept="image/*"
          @on-success="successUpload"
        />
          <span class="d-none d-sm-inline">{{$t('Update')}}</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="(patientModel.ProfileImagePath= null)"
        >
          <span class="d-none d-sm-inline">{{$t('Remove')}}</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('NameEN')"
            label-for="Name"
          >
            <b-form-input
              id="Name"
              v-model="patientModel.NameEN"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('SecondNameEN')"
            label-for="SecondNameEN"
          >
            <b-form-input
              id="Name"
              v-model="patientModel.SecondNameEN"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('ThirdNameEN')"
            label-for="ThirdNameEN"
          >
            <b-form-input
              id="Name"
              v-model="patientModel.ThirdNameEN"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name EN -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('FamilyNameEN')"
            label-for="FamilyNameEN"
          >
            <b-form-input
              id="FamilyName"
              v-model="patientModel.FamilyNameEN"
            />
          </b-form-group>
        </b-col>

       
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('NameAR')"
            label-for="NameAR"
          >
            <b-form-input
              id="Name"
              v-model="patientModel.NameAR"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('SecondNameAR')"
            label-for="SecondNameAR"
          >
            <b-form-input
              id="Name"
              v-model="patientModel.SecondNameAR"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('ThirdNameAR')"
            label-for="ThirdNameAR"
          >
            <b-form-input
              id="Name"
              v-model="patientModel.ThirdNameAR"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name EN -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('FamilyNameAR')"
            label-for="FamilyNameAR"
          >
            <b-form-input
              id="FamilyName"
              v-model="patientModel.FamilyNameAR"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('IdNumber')"
            label-for="IdNumber"
          >
            <b-form-input
              id="FamilyName"
              v-model="patientModel.IdNumber"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Country -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('Country')"
            label-for="Country"
          >
            <v-select
            label="Name"
              v-model="patientModel.CountryID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="countries"
              :reduce="val => val.ID"
              :clearable="false"
              input-id="Country"
              @input="onSelectCountry()"
            />
          </b-form-group>
        </b-col>

        <!-- phonenumber -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('PhoneNumber')"
            label-for="PhoneNumber"
          >
          <VuePhoneNumberInput :placeholder="$t('PhoneNumber')" :default-country-code="defaultPhoneData.countryCode" @update="acceptNumber" v-model="patientModel.Phone" />

          </b-form-group>
        </b-col>
        <!-- Field: PreferredLanguage -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('PreferredLanguage')"
            label-for="PreferredLanguage"
          >
            <v-select
              v-model="patientModel.PreferredLanguageID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="val => val.ID"
              label="Name"
              input-id="PreferredLanguage"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="4"
        >
        <b-form-group :label="$t('Gender')">
          <b-form-radio-group
            id="Gender-radio-group"
            v-model="patientModel.GenderID"
            name="Gender"
            :options="genders"
          />
        </b-form-group>
        </b-col>

      </b-row>
    </b-form>

 

 
  </div>
</template>

<script>
import axios from "@/axios.js"
import { domain, mainSetting } from "@/gloabelConstant.js"
import moduleLanguage from "@/store/settings/language/moduleLanguage.js"
import { BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import vSelect from 'vue-select'

export default {
  components: {
    VuePhoneNumberInput,
    BFormRadioGroup,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      genders:[
       { text : this.$t('male') , value :2},
       { text : this.$t('female') , value :1}

      ],
      defaultPhoneData:{
        countryCode : ""
      },
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      // patientModel:{},
      options: [],
      radios1: "luis",
    };
  },
  props: {
    patientModel: {
      type: Object,
      required: true,
    },
  },
  watch: {
    patientModel () {
      this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.patientModel.CountryID).IsoCode
    }
  },
  methods: {
     acceptNumber(value) {
      debugger
       this.patientModel.phonevalue = value.e164;
       this.patientModel.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
    },
    changeMode() {
      debugger;
      this.patientModel.Next = true;
    },

    onSelectCountry() {
      // debugger;
      // this.patientModel.Phone =
      //   "+" + this.countries.find((x) => x.ID === this.patientModel.CountryID).DailCode;
        this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.patientModel.CountryID).IsoCode
    },
    checkDailCod() {
      debugger;
      var code = this.countries.find((x) => x.ID === this.patientModel.CountryID)
        .DailCode;
      if (this.patientModel.Phone.length <= code.length) {
        this.patientModel.Phone = "+" + code;
      }
    },

    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.patientModel.ProfileImagePath = response.data;
        });
      }
    },
  },
  computed: {
    languages() {
      return this.$store.state.LanguageList.languages;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  created() {
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }
    this.$store.dispatch("LanguageList/GetAllLanguages");

this.onSelectCountry();
    //  axios.get('https://extreme-ip-lookup.com/json/')
    //   .then(response => {
    //      debugger;
    //   this.patientModel.NameEN = response.data.country;
    //    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
