import axios from "@/axios.js";

export default {
  AddCompany({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/InsuranceCompany/AddInsuranceCompany", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetAllInsuranceCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/InsuranceCompany/GetAllInsuranceCompanies")
        .then((response) => {
          commit('SET_COMPANY', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  UpdateCompany({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/InsuranceCompany/UpdateInsuranceCompany", item)
        .then((response) => {
          commit('UPDATE_COMPANY', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetCompany({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/InsuranceCompany/GetInsuranceCompany", itemid)
        .then((response) => {
          commit('UPDATE_COMPANY', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteInsuranceCompany({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/InsuranceCompany/DeleteInsuranceCompany?ID=" + item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}