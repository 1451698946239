/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
    AddCountry({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/AddCountry", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.Data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetAllCountries({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Country/GetAllCountries")
                .then(response => {
                    commit("SET_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllAppointmentCountries({ commit },model) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/GetAllAppointmentCountries",model)
                .then(response => {
                    commit("SET_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllAppointmentSurgeryCountries({ commit },model) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/GetAllAppointmentSurgeryCountries",model)
                .then(response => {
                    commit("SET_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAlMedicalPackagesCountries({ commit },model) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/GetAlMedicalPackagesCountries",model)
                .then(response => {
                    commit("SET_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllDoctorCountries({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Country/GetAllDoctorCountries")
                .then(response => {
                    commit("SET_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    
    GetAllHospitalSurgeryCountries({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Country/GetAllHospitalSurgeryCountries")
                .then(response => {
                    commit("SET_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getCountries() {
        fetch('https://extreme-ip-lookup.com/json/').then(res => res.json())
            .then(response => {
                this.patientModel.NameEN == response.country;
            })
    },

    GetDoctorCountry({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/SearchCountries", { "IsAvailableToDoctors": true })
                .then(response => {
                    commit("SET_DoctorCountry", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetAllHospitalCountries({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Country/GetAllHospitalCountries")
                .then(response => {
                    commit("SET_HospitalCountry", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
  
    GetTopDestinations({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/SearchCountries", { "IsTopDestinations": true })
                .then(response => {
                    commit("SET_TopDestinations", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateCountry({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/UpdateCountry", item)
                .then(response => {
                    commit("UPDATE_Country", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetCountry({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/GetCountry", itemid)
                .then(response => {
                    commit("UPDATE_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    DeleteCountry({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .delete("api/Country/DeleteCountry?ID=" + item.ID)
                .then(response => {
                    commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchHospitals({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Hospital/SearchHospitals", item)
                .then(response => {
                    commit("SET_Hospitals", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};