
import state from './moduleInsuranceCompanyState.js'
import mutations from './moduleInsuranceCompanyMutations.js'
import actions from './moduleInsuranceCompanyActions.js'
import getters from './moduleInsuranceCompanyGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}