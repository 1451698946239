<template>
  <component :is="patientModel === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="patientModel === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="patientModel" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{$t('MainData')}}</span>
        </template>
        <min-info :patientModel="patientModel" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('socialhistory')}}</span>
        </template>
        <medical-history :patientModel="patientModel" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
       <!-- Action Buttons -->
       <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="Submit()"
    >
      {{$t('SaveChanges')}}
    </b-button>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </component>
</template>

<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleInsuranceCompany from "@/store/settings/insuranceCompany/moduleInsuranceCompany.js";
import { BAlert, BButton, BCard, BLink, BTab, BTabs } from "bootstrap-vue";
import medicalHistory from "./medicalHistory.vue";
import minInfo from "./minInfo.vue";

export default {
  components: {
    BTab,
    BButton,
    BTabs,
    BCard,
    BAlert,
    BLink,

    minInfo,
    medicalHistory,
  },
  data() {
    return {
      patientModel: {
        PatientMedicalHistory: {
          PatientMedicalHistoryPastSurgeries: [],
          PatientMedicalHistoryMedications: [],
        },
        Next: true,
        BirthDate: new Date(),
      },
      options: [],
      radios1: "luis",
    };
  },

  methods: {
    Submit() {
      this.$vs.loading();

      this.$store
        .dispatch("patientList/UpdatePatient", this.patientModel)
        .then((res) => {
          console.error(res);
          window.showSuccess("successfully updated! please login");
          this.$vs.loading.close();
          this.$router.push({ name: "LandingPage" });

          // this.initPatientModelValues();
        })
        .catch((err) => {
          window.showError(err.response.data.message);
          this.$vs.loading.close();
          console.error(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.patientModel.ProfileImagePath = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  computed: {
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  created() {
    if (!moduleInsuranceCompany.isRegistered) {
      this.$store.registerModule(
        "InsuranceCompanyList",
        moduleInsuranceCompany
      );
      moduleInsuranceCompany.isRegistered = true;
    }

    this.$store.dispatch("InsuranceCompanyList/GetAllInsuranceCompanies");
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      debugger;

      this.$store
        .dispatch("patientList/GetPatientForMainData", ID)
        .then((res) => {
          this.patientModel = res.data.Data;
          this.$set(this.patientModel, "Next", false);
          debugger;
          if (
            this.patientModel.PatientMedicalHistory == null ||
            this.patientModel.PatientMedicalHistory == undefined
          ) {
            this.patientModel.PatientMedicalHistory = {
              PatientMedicalHistoryPastSurgeries: [],
              PatientMedicalHistoryMedications: [],
            };
          } else {
            debugger;
            if (this.patientModel.PatientMedicalHistory.IsLowPressure == true) {
              this.patientModel.PatientMedicalHistory.IsHavePresssure = null;
            }
            if (this.patientModel.NumOfChildren > 0) {
              this.patientModel.HaveChildren = true;
            }
          }
        });
    }
  },
};
</script>

<style>
</style>
