export default {
    ADD_ITEM(state, item) {
        state.InsuranceCompanies.unshift(item)
    },
    SET_COMPANY(state, InsuranceCompanies) {
        state.InsuranceCompanies = InsuranceCompanies
    },
    UPDATE_COMPANY(state, company) {
        const CompanyIndex = state.InsuranceCompanies.findIndex((p) => p.ID == company.ID)
        Object.assign(state.InsuranceCompanies[CompanyIndex], company)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.InsuranceCompanies.findIndex((p) => p.ID == itemId)
        state.InsuranceCompanies.splice(ItemIndex, 1)
    }
}